// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-first-mdx": () => import("./../../../src/pages/blog/first.mdx" /* webpackChunkName: "component---src-pages-blog-first-mdx" */),
  "component---src-pages-blog-second-mdx": () => import("./../../../src/pages/blog/second.mdx" /* webpackChunkName: "component---src-pages-blog-second-mdx" */),
  "component---src-pages-blog-third-mdx": () => import("./../../../src/pages/blog/third.mdx" /* webpackChunkName: "component---src-pages-blog-third-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

